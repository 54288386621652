import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import barbara from "../images/leadership_large/barbara.png"
import BlocProfile from "../components/blocprofile"

const barbara_lepetri = () => (
  <Layout theme="theme-dark">
    <SEO
      title="Barbara LePetri, MD"
      keywords={[
        `Scientific Narrative, Lexicon, Medical Communications, peer-to-peer, Investor relations, pipeline, KOL mapping,`,
      ]}
      description="Meet Barbara LePetri, MD. Medical and Scientific for Science Foundry"
    />

    <BlocProfile
      image={barbara}
      firstName="BARBARA"
      lastName="LEPETRI, MD"
      bio="Barbara leads a team of medical experts to drive scientific strategy innovation at The Bloc. As a board-certified internist and cardiologist, she brings a unique perspective informed by years of clinical practice, pharmaceutical, and agency experience. Today, her mission is to cultivate cross-functional medical prowess through instituting enterprise-wide initiatives with a focus on training across the organization."
      titleLineOne="Medical and Scientific Leadership"
      linkedIn="#"
      classes="hidden"
      imageAlt="Barbara LePetri, MD"
    />
  </Layout>
)

export default barbara_lepetri
