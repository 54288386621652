import React from "react"
import backIcon from "../images/icons/back-icon.png"

const BlocProfile = ({ image, firstName, lastName, titleLineOne, titleLineTwo, bio, imageAlt}) =>  {
  const backToLeaders = () => {
    window.history.back()
  }

    return (
      <div className="bio-wrapper section" data-color="black">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-12 d-block d-md-none">
              <div className="back-btn">
                <span onClick={backToLeaders} className="x-btn">
                  <img
                    src={backIcon}
                    alt="back to profile list"
                    loading="lazy"
                  />
                  <span className="back-title">back to Home</span>
                </span>
              </div>
            </div>
            <div className="col-md-7 order-md-12 profile-img-container">
              <img
                src={image}
                alt={imageAlt}
                loading="lazy"
                className="profile-img"
              />
            </div>
            <div className="col-md-5 order-md-1 bio-outer align-self-md-start">
              <div className="back-btn d-none d-md-block">
                <a href="/" className="x-btn">
                  <img
                    src={backIcon}
                    alt="back to profile list"
                    loading="lazy"
                  />
                  <span className="back-title">back to Home</span>
                </a>
              </div>
              <div className="bio-card back-show">
                <h1 className="bio-name nowrap">
                  {firstName}
                  <br />
                  {lastName}
                </h1>

                <div className="bio-card-title">
                  {titleLineOne} {titleLineTwo}
                </div>

                <div className="bio-card-bio non-tablet">{bio}</div>
              </div>
            </div>
            <div className="col-md-12 order-md-12 d-none d-md-block d-lg-none">
              <div className="bio-card-bio tablet">{bio}</div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default BlocProfile